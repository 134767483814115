<template>

    <HeaderSection />
    <div class="container-fluid d-flex justify-content-center my-5">
      <img class="img-fluid mx-h-500 max-h-90vh w-100" src="/img/gas-pump-night-2.jpg" alt=""/>
    </div>

    <!-- First title section -->
    <div class="container title-section text-center d-flex flex-column align-items-center mb-5">
        <h1 class="section-title">Benzinske stanice</h1>
        <p class="section-subtitle text-center w-75 mt-2">Ovo je mapa pokrivenosti Srbije sa Euro Petrol benzinskim stanicama. Klikom na benzinsku stanicu u meniju sa strane ili direktno na mapi, možete saznati sve bitne informacije o stanici i uslugama koje su dostupne na njoj.</p>
    </div>

    <!-- First title section end -->
    <!-- Map Section -->
    <div id="map-section" class="container mb-5">
      <div class="row gap-1">

        <!-- Left side picker -->
        <div class="col-lg">
          <div class="d-flex justify-content-between align-items-center">
            <span class="fw-bold color-main fs-4">Izaberite stanicu</span>
            <!-- <i class="ri-search-2-line fs-4 gray-4"></i> -->
          </div>
          <!-- Separation line -->
          <hr/>
          <!-- Locations Group -->
          <div class="d-flex flex-column gap-2 overflow-auto h-1k mb-3">
            <div v-for="station in gasStationsResponse" :key="station" v-bind:value="station" @click="selectStation(station)">
              <!-- Location list -->
              <div class="card p-3 me-2" @click="activeStationState(station.idmag_nord)" :class="{'selectedStation': selectedItem === station.idmag_nord}">
              <!-- <div class="card p-3 me-2" @click="getServices(station.nid)"> -->
                <div class="d-flex justify-content-between gap-1">
                  <!-- Location Info -->
                  <div class="d-flex gap-1">
                    <!-- Pin Icon -->
                    <i class="ri-map-pin-2-line"></i>
                    <b class="fw-bold">{{station.city}}</b> - {{station.address}}
                  </div>
                  <!-- Arrow Right Icon -->
                  <i class="ri-arrow-right-line"></i>
                </div>
              </div>
            </div>

          </div>

        </div>
        <!-- Right side map -->
        <div class="col-lg-8">
          <template v-if="selectedStation">
            <!-- Gas pricing title -->
            <div class="d-flex justify-content-between align-items-center">
              <span class="fw-bold color-main fs-4 pb-0">{{selectedStation.city}} - {{selectedStation.address}}</span>
              <div>
              <a :href="'https://www.google.com/maps/dir//' + selectedStation.location.lat + ',+' + selectedStation.location.lng" target="_blank" class="radius-0 btn btn-secondary text-decoration-none d-flex align-items-center gap-1" :aria-label="selectedStation.city">
                Putanja <i class="ri-compass-discover-line color-main fs-4"></i>
              </a>
              </div>
            </div>
            <!-- Separation line -->
            <hr/>
            <!-- Google Map -->
            <div class="mb-3 h-500">
              <!-- Place here - Google Map Snippet -->
              <!-- api-key="AIzaSyA9iMpjstgjVffJc2sEPealV1IC4f4nCEQ" -->
              <GoogleMap api-key="AIzaSyAboxdDSraLV0k-tlVX0dSYIM3Fpp23EV0" style="height: 100%;" :center="selectedStation.location" :zoom="9">
                <Marker :options="{ position: selectedStation.location }" />
              </GoogleMap>
            </div>
            <hr><div class="d-flex justify-content-between align-items-center">
              <span class="fw-bold color-main fs-4 pb-0">Aktuelne cene goriva na pumpi <small class="fw-normal fst-italic updated-notice">({{moment(selectedStation.fuelPricesLastUpdated).locale('sr').format('LLL')}})</small></span>
            </div><hr/>
            <!-- Gas pricing table start -->
            <table class="table table-bordered table-striped">
              <thead>
                <tr class="table-h-bg">
                  <th colspan="2">Vrsta i cena goriva</th>
                  <th>Euro Petrol Klub Cena</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="pricing in selectedStation.fuels" :key="pricing.product">
                  <td class="text-start">
                    <img :src="getFuelImg(pricing.uri)" height="50" :alt="pricing.title">
                    <strong class="mx-3">{{pricing.title}}</strong>
                  </td>
                  <td class="fs-5 fw-bold">{{pricing.regularPrice}}</td>
                  <td class="fs-5 fw-bold">{{pricing.discountPrice}}</td>
                </tr>
              </tbody>
            </table>
            <!-- Gas pricing table end -->
            <hr><div class="d-flex justify-content-between align-items-center">
              <span class="fw-bold color-main fs-4 pb-0">Dostupne usluge na Euro Petrol stanicama</span>
            </div><hr/>
            <div v-if="slug" class="row jc-services p-2">
              <tippy :maxWidth='maxWidth' class="p-0 h-125 col-lg-2 col-md-3 col-3">
                <div class="service-card bg-ep"><img :src="'/img/icon/' + serviceIcons[slug]" :alt="slug"></div>
              </tippy>
            </div>
            <div v-else class="row jc-services p-2">
              <tippy v-for="item in selectedStation.services" :key="item.product" :maxWidth='maxWidth' class="p-0 h-125 col-lg-2 col-md-3 col-3" :content='item.title' animation='perspective'>
                <div class="service-card bg-ep"><img :src="getImgUrl(item.uri)" :alt="item.title"></div>
              </tippy>
            </div>
            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <span class="fw-bold color-main fs-4 pb-0">Kontakt informacije</span>
            </div>
            <!-- Separation line -->
            <hr/>

            <table class="table table-bordered table-striped">
              <tbody>
                <tr v-if="selectedStation.city">
                  <td class="text-start">
                    <b>Grad:</b> {{selectedStation.city}}
                  </td>
                </tr>

                <tr v-if="selectedStation.address">
                  <td class="text-start">
                    <b>Adresa:</b> {{selectedStation.address}}
                  </td>
                </tr>

                <tr v-if="selectedStation.postal_code">
                  <td class="text-start">
                    <b>Poštanski broj:</b> {{selectedStation.postal_code}}
                  </td>
                </tr>

                <tr v-if="selectedStation.working_time">
                  <td class="text-start">
                    <b>Radno vreme:</b> {{selectedStation.working_time}}
                  </td>
                </tr>

                <tr v-if="selectedStation.phone">
                  <td class="text-start">
                    <b>Telefon:</b> {{selectedStation.phone}}
                  </td>
                </tr>

                <tr v-if="selectedStation.mobile">
                  <td class="text-start">
                    <b>Fax:</b> <strong class="mx-3">{{selectedStation.mobile}}</strong>
                  </td>
                </tr>

              </tbody>
            </table>
          </template>

          <template v-else>
            <div class="d-flex justify-content-between align-items-center">
              <span class="fw-bold color-main fs-4 pb-0">Lista benzinskih stanica</span>
            </div>
            <!-- Separation line -->
            <hr/>
            <GoogleMapSection
              v-if="gasStationsBs?.length > 0"
              :gasStations="gasStationsBs"
              :serviceID="slug"
            />
            <hr/>
            <div class="d-flex justify-content-between align-items-center mt-3">
              <span class="fw-bold color-main fs-4 pb-0">Dostupne usluge na Euro Petrol stanicama</span>
            </div>
          <!-- Separation line -->
          <hr/>


          <div v-if="slug" class="row gap-4 justify-content-evenly p-2">
            <tippy :maxWidth='maxWidth' class="p-0 h-125 col-lg-2 col-md-3 col-3">
              <div class="service-card bg-ep"><img :src="'/img/icon/' + serviceIcons[slug]" alt="slug"></div>
            </tippy>
          </div>

          <div v-else class="row gap-4 justify-content-evenly p-2">
            <tippy :maxWidth='maxWidth' class="p-0 h-125 col-lg-2 col-md-3 col-3" content='Dok točite gorivo proverite dešavanja na Internetu. Naše konekcije su brze, a za detalje se raspitajte kod zaposlenih.' animation='perspective'>
              <div class="service-card bg-ep"><img src="../assets/icon/wifi-03_0.png" alt="WiFi Internet"></div>
            </tippy>

            <tippy :maxWidth='maxWidth' class="p-0 h-125 col-lg-2 col-md-3 col-3" content='Postanite korisnik Euro Petrol Kartice i kupujte gorivo jeftinije i do 5 dinara po litru, uz razne druge specijalne pogodnosti!' animation='perspective'>
              <div class="service-card bg-ep"><img src="../assets/icon/club.png" alt="EP Klub"></div>
            </tippy>

            <tippy :maxWidth='maxWidth' class="p-0 h-125 col-lg-2 col-md-3 col-3" content='U koje god doba dana i noći da dođete, Euro Petrol benzinske stanice biće otvorene za Vas, a naši zaposleni na raspolaganju.' animation='perspective'>
              <div class="service-card bg-ep"><img src="../assets/icon/0-24_2.png" alt="0-24 Radno Vreme"></div>
            </tippy>

            <tippy :maxWidth='maxWidth' class="p-0 h-125 col-lg-2 col-md-3 col-3" content='Kod nas možete dopuniti TAG uređaj za elektronsku naplatu putarine. Prolazite kroz naplatne rampe na auto putu bez zadržavanja.' animation='perspective'>
              <div class="service-card bg-ep"><img src="../assets/icon/enp_ikonica_za_sajt_120x120px-02.png" alt="ENP"></div>
            </tippy>

            <tippy :maxWidth='maxWidth' class="p-0 h-125 col-lg-2 col-md-3 col-3" content='Primamo sve poznatije kartice, tako da Vam gotovina na našim benzinskim stanicama neće biti potrebna.' animation='perspective'>
              <div class="service-card bg-ep"><img src="../assets/icon/kartic_2.png" alt="Kartica"></div>
            </tippy>

            <tippy :maxWidth='maxWidth' class="p-0 h-125 col-lg-2 col-md-3 col-3" content='Mislite na svoju sigurnost u vožnji i redovno proveravajte pritisak u pneumaticima na našim benzinskim stanicama.' animation='perspective'>
              <div class="service-card bg-ep"><img src="../assets/icon/pumpanje-guma.png" alt="Pumpanje guma"></div>
            </tippy>

            <tippy :maxWidth='maxWidth' class="p-0 h-125 col-lg-2 col-md-3 col-3" content='Napravite predah od puta i napunite se dobrom energijom uz ukusan obrok u prijatnom ambijentu naših restorana.' animation='perspective'>
              <div class="service-card bg-ep"><img src="../assets/icon/restoran_0.png" alt="Restoran"></div>
            </tippy>

            <tippy :maxWidth='maxWidth' class="p-0 h-125 col-lg-2 col-md-3 col-3" content='Naši Lion Shop-ovi snabdeveni su bogatim asortimanom proizvoda, od najsitnijih potrepština do auto kozmetike. Za svakoga po nešto.' animation='perspective'>
              <div class="service-card bg-ep"><img src="../assets/icon/shop.png" alt="Prodavnica"></div>
            </tippy>

            <tippy :maxWidth='maxWidth' class="p-0 h-125 col-lg-2 col-md-3 col-3" content='Naši samouslužni usisivači, izuzetne snage i laki za upotrebu, učiniće Vaš auto čistim iznutra u. svega nekoliko minuta' animation='perspective'>
              <div class="service-card bg-ep"><img src="../assets/icon/usisavanje.png" alt="Usisavanje"></div>
            </tippy>

            <tippy :maxWidth='maxWidth' class="p-0 h-125 col-lg-2 col-md-3 col-3" content='Naši samouslužni usisivači, izuzetne snage i laki za upotrebu, učiniće Vaš auto čistim iznutra u. svega nekoliko minuta' animation='perspective'>
              <div class="service-card bg-ep"><img src="../assets/icon/beskontaktno-pranje_2.png" alt="Usisavanje"></div>
            </tippy>

          </div>

          </template>

        </div>

      </div>
    </div>
    <!-- Map Section End -->
</template>

<script>
import HeaderSection from "../components/UI/AlternativeHeader.vue";
import { Tippy, directive } from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/perspective.css';
import { ref as reactivityRef } from '@vue/reactivity';
import { GoogleMap, Marker } from 'vue3-google-map';
import GoogleMapSection from "../components/widgets/GoogleMap.vue";
import epk_db from '@/getFuelPrices';
import { ref, onValue } from "firebase/database";
import moment from 'moment';

export default {
  setup() {
    /*Static Change in Tippy's max width*/
    const maxWidth = reactivityRef('250px');
    if(window.innerWidth < 992)
      maxWidth.value = '80vw';

    return {
      maxWidth,
      moment
    }

  },

  data(){
    return {
      // Sorted gas stations by the name
      sortedStationLocations: [],
      gasStationsResponse: [],
      selectedStation: '',
      stationPin: null,
      activeStation: false,
      stationLocation: {},
      selectedItem: '',
      fuelListLoaded: false,
      stationInfoParsed: false,
      fuelParsingFinished: false,
      slug: null,
      serviceIcons: {
        40    : '0-24_2.png',
        572   : 'adblue_ikonica_120x120px_0.jpg',
        32    : 'beskontaktno-pranje_2.png',
        321   : 'club-card_2.png',
        438     : 'club.png',
        610   : 'easy_paketi_ikonica_120x120px.jpg',
        584   : 'electro-power-piktogram-120x120-px_002.png',
        524   : 'enp_ikonica_za_sajt_120x120px-02.png',
        324   : 'kafa.png',
        325   : 'kafa-za-poneti_2.png',
        563   : 'kamper-piktogram-120x120-px.jpg',
        38    : 'kartic_2.png',
        33    : 'masinsko-pranje_2.png',
        327   : 'menjacnica_2.png',
        35    : 'pumpanje-guma.png',
        43    : 'restoran_0.png',
        42    : 'shop.png',
        36    : 'usisavanje.png',
        39    : 'wifi-03_0.png'
      }
    };
  },

  async created() {
    //
    this.slug = this.$route.params.serviceID;

    const euroPetrolBody = JSON.stringify({"url":"https://app.lion-group.rs/epk/v2","action":"updateBS","data":{}});

    // POST request using fetch with async/await
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
       },
      body: euroPetrolBody
    };
    const response = await fetch(
      "https://app.lion-group.rs/epk/v2",
      requestOptions
    );
    const data = await response.json();

    const fuelData = ref(epk_db, 'epk');
    this.fuelListLoaded = false;
    this.stationInfoParsed = false;

    onValue(fuelData, (snapshot) => {
        this.fuelList = JSON.parse(JSON.stringify(snapshot.val()));
        this.fuelListLoaded = true;

        this.parseFuelInfo();
    });

    // Main gas stations array
    this.gasStations = data;
    // Gas Stations -> BS
    this.gasStationsBs = this.gasStations.data.bs;
    // Sort Gas Stations by Name
    this.sortedStationLocations = this.gasStationsBs.sort((a, b) =>
      a.city.localeCompare(b.city)
    );
    // Gas station services and fuels - list of all of the possible items
    this.allStationServices = this.gasStations.data.services;
    // Gas station connector for services
    this.servicesConnector = this.gasStations.data.stationServices;

    // Gas station connector for fuels
    this.fuelsConnector = this.gasStations.data.stationFuels;

    // Filter the stations if there is a service ID sent in the URL
    if (this.slug) {
      let stationsCount = this.gasStationsBs.length;
      for (let g = stationsCount - 1; g >= 0; g--) {
        let servicesOfThisStation = this.servicesConnector[this.gasStationsBs[g]['idmag_nord']];
        if (!servicesOfThisStation.includes(parseInt(this.slug))) {
            this.gasStationsBs.splice(g, 1);;
        }
      }
    }

    // loop through all the stations
    this.gasStationsBs.forEach((station) => {
      // this will contain the services and fuels for the specific station
      station.services = [];
      station.fuels = [];
      station.location = { lat: parseFloat(station.lat), lng: parseFloat(station.lng) };

      // console.log(this.stationLocation);

      // list of services this station has
      var servicesOfThisStation = this.servicesConnector[station.idmag_nord];

      // list of fuels this station has
      var fuelListOfThisStation = this.fuelsConnector[station.idmag_nord];

      // loop through the service ids of this station, and if we find a match in the all service list, return it
      servicesOfThisStation.forEach((serviceId) => {

        var serviceInfo = this.allStationServices.find((serviceListItem) => {
          if (serviceListItem.product && serviceListItem.product == serviceId) {
            return true;
          }
          return false;
        });

        // avoid passing by reference
        var serviceInfoItem = Object.assign({}, serviceInfo);

        // add the main info into the services array
        station.services.push(serviceInfoItem);
      });

      // loop through the fuel ids of this station, and do the same as above
      fuelListOfThisStation.forEach((fuelId) => {

        var fuelInfo = this.allStationServices.find((serviceListItem) => {
          if (serviceListItem.product && serviceListItem.product == fuelId) {
            return true;
          }
          return false;
        });

        // avoid passing by reference
        var fuelInfoItem = Object.assign({}, fuelInfo);

        // add the fuel info to the fuels array
        station.fuels.push(fuelInfoItem);
      });
    });

    this.gasStationsResponse = this.gasStationsBs;
    this.stationInfoParsed = true;
    this.parseFuelInfo();

    if(this.$route.query.station) {
      const queryStation = this.gasStationsResponse.find(
        (gStation) => gStation.idmag_nord == this.$route.query.station
      )
      this.selectStation(queryStation);
      this.activeStationState(queryStation.idmag_nord);
      document.getElementById("map-section").scrollIntoView();
    }
    // parsed data, use this
    // console.log("this.gasStationsBs", this.gasStationsBs);
  },

  methods:{
    // Gets picture URL
    getImgUrl(uri) {
        return('./'+uri);
    },
    getFuelImg(uri){
      return('./fuels'+uri)
    },
    // Gets selected station, clears is if already exist, then adds new selected value
    selectStation(station){
      if(this.selectedStation){
        this.selectedStation = null;
      }
      this.selectedStation = station;

      this.$router.push({
        query: {
          station: station.idmag_nord
        },
      });

      var selectedStationId = station.idmag_nord;

      this.gasStationsResponse.forEach((stationItem) => {
        if (stationItem.idmag_nort == selectedStationId) {
          // avoid passing by reference
          this.selectedItem = Object.assign({}, stationItem);
        }
      });

      return true;
    },

    activeStationState(id){
      this.selectedItem = id;
    },

    parseFuelInfo() {
      if (this.fuelListLoaded === false || this.stationInfoParsed === false) {
        // make sure we parse only when both request have finished
        return;
      }

      this.gasStationsResponse.map((station) => {

        var stationId = station.idmag_nord;

        station.fuelPricesLastUpdated = this.fuelList.azurirano;

        station.fuels.map((stationFuel) => {
          var fuelId = stationFuel.product;
          stationFuel.regularPrice = null;
          stationFuel.discountPrice = null;

          if (this.fuelList.goriva[stationId] && this.fuelList.goriva[stationId][fuelId]) {
            stationFuel.regularPrice = this.fuelList.goriva[stationId][fuelId];
          }

          if (this.fuelList.gorivaPopusti[stationId] && this.fuelList.gorivaPopusti[stationId][fuelId]) {
            stationFuel.discountPrice = this.fuelList.gorivaPopusti[stationId][fuelId];
          }

          return stationFuel;
        });

        return station;
      });

      this.fuelParsingFinished = true;
      this.gasStationsResponse = this.gasStationsBs;
    }
  },

  components: {
    HeaderSection,
    GoogleMap,
    Tippy,
    Marker,
    GoogleMapSection,
  },

  directives: {
      tippy: directive,
  },
};

</script>

<style scoped>

.tippy-box {
  background: red;
}

.updated-notice{
  font-size: 15px;
}

.selectedStation{
  background-color: var(--color-main);
  color: white;
}

.selectedStation i{
  color: var(--color-secondary);
}

td{
  text-align: center;
  vertical-align: middle;
}

.card{
  border-radius: 0px;
  cursor: pointer;
}

.gray-4{
  color: #BDBDBD;
}

.service-card{
  display: flex;
  justify-content: center;
  align-items: center;
}

.h-125{
  height: 125px;
}

.h-500{
  height: 500px;
}

.mx-h-500{
  min-height: 300px;
  object-fit: cover;
  object-position: 50% 35%;
}

.h-1k{
  height: 1125px;
}

.jc-services{
  justify-content: flex-start;
  gap: 1.5rem;
}

.card:hover{
  background-color: var(--color-main);
  color: white !important;
  -webkit-animation: progressbar 1s ease infinite;
  -moz-animation: progressbar 1s ease infinite;
  -o-animation: progressbar 1s ease infinite;
  animation: progressbar 1s ease infinite;
}

.table-h-bg{
  background-color: var(--color-main);
  color: white;
}

.card:hover .ri-map-pin-2-line{
  color: var(--color-secondary);
}

@media (max-width: 992px) {
  .h-1k {
    height: 500px
  }

  .jc-services{
    justify-content: space-evenly;
    gap: 0.5rem;
  }
}
</style>
