<template>
    <!-- Electric Chargers Section Start -->
    <section id="open-positions" class="d-flex justify-content-center py-4 mt-5">
        <div class="container">
            <!-- Main Title with Subtitle -->
            <h1 class="section-title color-main my-4 pb-3">Euro Petrol Klub</h1>
            <!-- Main Title with Subtitle End -->
            <a href="/euro-petrol-klub">
              <img src="/img/ep-klub-banner.png" class="img-fluid py-2 w-100" alt="Electric Chargers">
            </a>
            <div class="row justify-content-between py-5">
                <div class="col-lg-6 d-flex flex-column">
                    <span class="fw-bold mb-2 fs-5 color-main">Akcije i specijalni popusti</span>
                    <p class="section-subtitle">Preuzmite Euro Petrol mobilnu aplikaciju i odmah ostvarite uštedu na gorivu! Za svega nekoliko minuta se putem aplikacije učlanite u Euro Petrol. EPK kartica će uvek biti sa Vama – na Vašem mobilnom telefonu.</p>
                    <p class="fw-bold color-main mt-4 mb-0 fs-5">Više od 185.000 članova Euro Petrol Kluba ima mogućnost da brzo i lako:</p>
                    <ul class="section-subtitle">
                        <li>Prati evidenciju potrošnje goriva</li>
                        <li>Proveri iznos ušteda na gorivu</li>
                        <li>Pronađe lokaciju najbliže Euro Petrol benzinske stanice</li>
                        <li>Informiše se o aktuelnim popustima i akcijama</li>
                    </ul>
                </div>

                <div class="col-lg-6 d-flex flex-column">
                    <span class="fw-bold mb-2 fs-5 color-main">Brzo i jednostavno</span>
                    <p class="section-subtitle">Članstvo je potpuno besplatno i bezuslovno, dok je procedura učlanjenja veoma laka i jednostavna. Uštedite i do 7 dinara na svaki kupljeni litar goriva.</p>
                    <p class="fw-bold color-main mt-4 mb-0 fs-5">Učlanjenjem u Euro Petrol Klub, dobijate EPK karticu koja vam omogućava da:</p>
                    <ul class="section-subtitle">
                        <li>Prilikom svakog tankovanja uštedite do 7 dinara po litru goriva</li>
                        <li>Ostvarite značajne popuste na auto kozmetiku i usluge pranja i usisavanja vozila</li>
                        <li>Da prvi saznate za sve akcije i specijalne popuste!</li>
                    </ul>
                </div>

                <div class="col-lg-12 my-2">
                    <button aria-label="Detaljnije informacije" class="btn btn-secondary lh-lg rounded-0 d-flex align-items-center gap-1" type="submit"  @click="isOpen = !isOpen;"> Detaljnije informacije o popustima <i class="ri-download-line color-main"></i></button>
                <transition name="modal-custom">
                <div v-if="isOpen">
                    <div class="overlay-modal" @click.self="isOpen = false;">
                        <div class="modal-custom">
                            <img src="../../assets/media/ep-cenovnik.jpg" class="img-fluid" alt="Informacije o Euro Petrol Klub popustima">
                        </div>
                    </div>
                </div>
                </transition>

                </div>
            </div>
        </div>
    </section>
    <!-- Electric Chargers Section End -->
</template>

<script>
export default {
  data: function() {
    return {
      isOpen: false
    };
  }
};
</script>

<style scoped>
.job-item{
    padding: 2.6em;
    border: 1px solid #e0e0e0;
}

.mw-47rem{
    max-width: 47em;
}

.modal-custom {
  display: flex;
  align-items: center;
  width: 50%;
  height: 80%;
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px 3px;
  transition: all 0.2s ease-in;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: auto;
  touch-action: pan-y !important;
}
.fadeIn-enter {
  opacity: 0;
}

.fadeIn-leave-active {
  opacity: 0;
  transition: all 0.2s step-end;
}

.fadeIn-enter .modal,
.fadeIn-leave-active.modal {
  transform: scale(1.1);
}

.overlay-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 9999;
  transition: opacity 0.2s ease;
}

@media (max-width: 900px) {
  .modal-custom{
    width: 100%;
    height: 70%;
  }
}
</style>