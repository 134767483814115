<template>
    <AlternativeHeader />

    <!-- Header Image Start -->
    <div class="container-fluid d-flex justify-content-center my-5">
        <img class="img-fluid mx-h-300 w-100" src="/img/ep-klub-banner.png" alt="kasir-header" />
    </div>
    <!-- Header Image End -->

    <!-- First title section -->
    <div class="container title-section text-center d-flex flex-column align-items-center mb-4">
        <h1 class="section-title">Učlanite se u EPK</h1>
        <p class="section-subtitle text-center w-75 mt-2">Postanite član Euro Petrol Kluba, brzo i jednostavno.</p>
    </div>
    <!-- First title section end -->

    <!-- Main container -->
    <section id="privay-policy" class="container py-5">
        <ul class="nav nav-tabs" id="epk-switcher" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link text-dark active" id="fizicka-lica-tab" ref="fizicka-lica" data-bs-toggle="tab" data-bs-target="#fizicka-lica" type="button" role="tab" aria-controls="fizicka-lica" aria-selected="true">Fizička lica</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link text-dark" id="poljoprivrednici-tab" ref="poljoprivrednici" data-bs-toggle="tab" data-bs-target="#poljoprivrednici" type="button" role="tab" aria-controls="poljoprivrednici" aria-selected="false">Poljoprivrednici</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link text-dark" id="taksisti-tab" ref="taksisti" data-bs-toggle="tab" data-bs-target="#taksisti" type="button" role="tab" aria-controls="taksisti" aria-selected="false">Taksisti</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link text-dark" id="pravna-lica-tab" ref="pravna-lica" data-bs-toggle="tab" data-bs-target="#pravna-lica" type="button" role="tab" aria-controls="pravna-lica" aria-selected="false">Pravna lica</button>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <!-- Fizicka lica -->
            <div class="tab-pane fade p-4 show active" id="fizicka-lica" role="tabpanel" aria-labelledby="fizicka-lica-tab">
                <h3 class="fw-bold mb-3 color-main">Članstvo je potpuno besplatno i bezuslovno.</h3>
                <p>Procedura učlanjivanja je jednostavna i laka.</p>
                <p>Uštedite i do 7 dinara na svaki kupljeni litar goriva!</p>
                <p>Učlanjenjem u Euro Petrol Klub, dobijate EPK karticu koja vam omogućava da:</p>

                <ul>
                    <li>
                        Prilikom SVAKOG tankovanja uštedite do 7 dinara po litri goriva.
                    </li>

                    <li>
                        Ostvarite značajne popuste na <b>auto kozmetiku</b> i <b>usluge pranja i usisavanja vozila.</b>
                    </li>

                    <li>
                        Prvi saznajte za sve akcije i specijalne popuste!
                    </li>
                </ul>

                <p>Budite deo Euro Petrol kluba i uštedite svaki put kada dođete da tankujete! Jer, Euro Petrol ceni Vašu vernost i lojalnost! <b>Brzo i lako do popusta</b>.</p>

                <div class="col-lg-12 my-4">
                    <button class="btn btn-secondary lh-lg rounded-0 d-flex align-items-center gap-1" type="submit"  @click="isOpen = !isOpen;"> Detaljnije informacije o popustima <i class="ri-download-line color-main"></i></button>
                    <transition name="modal-custom">
                    <div v-if="isOpen">
                        <div class="overlay-modal" @click.self="isOpen = false;">
                            <div class="modal-custom">
                                <img src="../assets/media/ep-cenovnik-7.jpg" class="img-fluid" alt="Informacije o Euro Petrol Klub popustima">
                            </div>
                        </div>
                    </div>
                    </transition>
                </div>

                <p>Preuzmite <b>Euro Petrol</b> mobilnu aplikaciju I <b>odmah</b> ostvarite <b>uštedu</b> na gorivu! Za svega nekoliko minuta se putem aplikacije učlanite u <b>Euro Petrol</b>. EPK kartica će uvek biti sa vama – na Vašem mobilnom telefonu. Uz mobilnu aplikaciju, više od 150.000 članova Euro Petrol Kluba ima mogućnost da <b>BRZO I LAKO</b>:</p>

                <ul>
                    <li>Prati evidenciju potrošnje goriva</li>
                    <li>Proveri iznos ušteda na gorivu</li>
                    <li>Pronađe lokaciju najbliže EP benzinske stanice</li>
                    <li>Informiše se o aktuelnim popustima i akcijama</li>
                </ul>

                <div class="container pb-5">
                    <div class="d-inline-flex gap-3 py-3 flex-wrap justify-content-center">
                        <a href="https://apps.apple.com/us/app/euro-petrol/id1459216332" target="_blank" rel="noopener" class="btn bg-black-20 px-4 py-3">
                            <img src="../assets/media/app-store-btn.svg" alt="App store">
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.europetrol.epk" target="_blank" rel="noopener" class="btn bg-black-20 px-4 py-3">
                            <img src="../assets/media/play-store-btn.svg" alt="Play Store">
                        </a>
                        <a href="https://appgallery.huawei.com/app/C104584461" target="_blank" rel="noopener" class="btn bg-black-20 px-4 py-3">
                            <img src="../assets/media/app-gallery-btn.svg" alt="App Gallery">
                        </a>
                    </div>
                </div>

            </div>
            <!-- Poljoprivrednici -->
            <div class="tab-pane fade p-4" id="poljoprivrednici" role="tabpanel" aria-labelledby="poljoprivrednici-tab">
                <img class="img-fluid" src="../assets/media/kad-domacin-bira.png" alt="kad-domacin-bira" />
                <h3 class="fw-bold mt-3 color-main">Šta obezbeđuje EPK kartica?</h3>
                <p class="mb-3">EPK Agro kartica obezbeđuje popuste na sve vrste goriva, kao i popuste na proizvode i usluge u shopu.</p>
                <h3 class="fw-bold mt-3 color-main mb-3">Kako do EPK kartice?</h3>
                <ul>
                    <li>Potrebno je dostaviti:
                        <ul>
                            <li>Kopiju lične karte</li>
                            <li>Kontakt telefon</li>
                        </ul>
                    </li>
                    <li>Poslati potrebnu dokumentaciju na adresu: Euro Petrol doo, Senćanski put 75, 24000 Subotica, ili predati dokumentaciju na BS Euro Petrola</li>
                </ul>

                <h3 class="fw-bold mt-3 color-main">Ko može postati korisnik EPK kartice?</h3>
                <p class="mb-3">Korisnik EPK kartice može posatiti punoletna osoba koja obavlja poljoprivrednu proizvodnju.</p>
                <h3 class="fw-bold mt-3 color-main">Za koliko dana stiže EPK kartica?</h3>
                <p class="mb-3">EPK Kartica stiže najaksnije za 5 radni dana na adresu koju ste naveli, ili na BS Euro Petrola gde ste predali dokumentaciju.</p>
                <h3 class="fw-bold mt-3 color-main">Da li se EPK kartica naplaćuje?</h3>
                <p class="mb-3">EPK kartica se ne naplaćuje I služi kao sredstvo za ostvaravinje prava na popust. Odnosi se samo na gotovinsko plaćanje.</p>
            </div>
            <!-- Taksisti -->
            <div class="tab-pane fade p-4" id="taksisti" role="tabpanel" aria-labelledby="taksisti-tab">
                <img class="img-fluid" src="../assets/media/brzo-i-lako-sa-taxi.png" alt="kad-domacin-bira" />
                <h3 class="fw-bold mt-3 color-main">Šta obezbeđuje EPK Taksi Kartica?</h3>
                <p class="mb-3">EPK Taksi kartica obezbeđuje popuste na sve vrste goriva:</p>
                <ul>
                    <li>Auto gas TNG: <b>6 rsd/litar</b></li>
                    <li>Evro Premijum BMB 95: <b>4 rsd/litar</b></li>
                    <li>Lion Expert BMB 100: <b>9 rsd/litar</b></li>
                    <li>Lion Expert Diesel: <b>10 rsd/litar</b></li>
                    <li>Evro dizel:  <b>7 rsd/litar</b></li>
                </ul>
                <p>kao i popuste na proizvode i usluge u shopu.</p>

                <h3 class="fw-bold mt-3 color-main">Kako do EPK Taksi kartice?</h3>
                <p class="mb-3">Do EPK Taksi kartice u samo 4 koraka.</p>
                <p>Potrebno je da:</p>
                <ul>
                    <li><b>Prikupite</b> potrebnu dokumentaciju
                        <ul>
                            <li>Kopija lične karte.</li>
                            <li>Potvrda o aktivnom statusu u Registru privrednih subjekata.</li>
                            <li>Fotokopija saobraćajne dozvole.</li>
                        </ul>
                    </li>
                    <li><b>Preuzmete pristupnicu</b> na benzinskoj stanici ili je preuzmite sa sajta (<a target="_blank" class="color-main" href="./forms/ep_zahtev_za_taxi_kartice.pdf">EP zahtev za Taksi kartice.pdf</a>)</li>
                    <li><b>Popunite pristupnicu.</b> Zaposleni na BS će rado pomoći oko svih potrebnih informacija i načina popunjavanja pristupnice.</li>
                    <li><b>Pošaljete potrebnu dokumentaciju</b> na adresu: DOO Euro Petrol, Senćanski put 75, 24000 Subotica, PAK 24107 (Odeljenje za izradu kartica)  ili je predate na EP benzinskoj stanici.</li>
                </ul>

                <p><i>Za detaljne informacije možete pozvati besplatnu info liniju 0800177 777 ili  call centar  024 664 664. Nije moguće on-line učlanjenje u AGRO EPK.</i></p>

                <h3 class="fw-bold mt-3 color-main">Ko može postati korisnik EPK Taksi kartice?</h3>
                <p class="mb-3">Korisnik EPK Taksi kartice može da postane svako lice koje vrši uslugu taxi prevoza, samostalno ili kod drugog preduzetnika/pravnog lica, a koje poseduje važeću dozvolu za pružanje usluga taxi prevoza izdatu od strane nadležnog organa i koje je korisnik vozila koje je u skladu sa propisima Republike Srbije označeno kao taxi vozilo (registarska oznaka TX).</p>
                <p class="mb-3">EPK Taksi kartice su kartice na registarsku oznaku vozila.</p>
                <h3 class="fw-bold mt-3 color-main">Za koliko dana stiže Taksi kartica?</h3>
                <p class="mb-3">EPK Taksi KARTICA stiže najkasnije za 5 radnih dana, na kućnu adresu, odnosno na adresu navedenu u pristupnici.</p>
                <h3 class="fw-bold mt-3 color-main">Da li se Taksi kartica plaća?</h3>
                <p class="mb-3">Kartica je besplatna, glasi na ime i nije prenosiva. EPK TAXI KARTICA je sredstvo za ostvarivanje prava na popust i ne može biti prihvaćena kao sredstvo plaćanja za robu. Odnosi se samo na gotovinsko plaćenje.</p>

            </div>
            <div class="tab-pane fade p-4" id="pravna-lica" role="tabpanel" aria-labelledby="pravna-lica-tab">
                <p><b>EURO PETROL CARD (EPC)</b> je kartica kojom se vrši evidencija transakcija na EURO PETROL benzinskim stanicama na teritoriji Republike Srbije.</p>
                <img class="img-fluid" src="../assets/media/baner.png" alt="jednostavna-evidencija-transakcija" />
                <p><b>EPC  obezbeđuje</b>:</p>
                <ul>
                    <li>Kompletan "online" uvid i kontrolu troškova goriva</li>
                    <li>Pojednostavljene  knjigovodstvene poslove – zbirni računi</li>
                    <li>Optimalizaciju gotovinskih izdatka preduzeća</li>
                    <li>Zaštitu pojedinačne kartice PIN kodom</li>
                    <li>Razdvojen putnički i teretni program</li>
                    <li>Mogućnost limitiranja kartica na mesečnom nivou</li>
                </ul>
                <p>EPC pojednostavljuje korišćenje usluga na našim prodajnim mestima. Do kartice se stiže jednostavno i besplatno, bez mesečnih troškova održavanja, bez uslovljavanja minimalnom mesečnom potrošnjom i polaganja depozita.</p>
            </div>
        </div>
    </section>
</template>

<script>
import AlternativeHeader from "../components/UI/AlternativeHeader.vue";

export default {
    data: function() {
        return {
            isOpen: false
        };
    },
    components: {
        AlternativeHeader
    },
    mounted() {
        this.goToTab();
    },
    methods: {
        goToTab() {
            var parts = window.location.href.split('#');
            if (parts.length > 1) {
                var tab = parts[1];
                if (this.$refs[tab]) this.$refs[tab].click();
            }
        }
    }
}

</script>

<style scoped>
.section-title{
    text-transform: initial;
}

.bg-black-20{
    background-color: var(--color-main);
}

#epk-switcher .active{
    color: var(--color-main) !important;
    font-weight: bold;
}

.mw-47rem{
    max-width: 47em;
}

.modal-custom {
  width: 50%;
  height: 80%;
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px 3px;
  transition: all 0.2s ease-in;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: auto;
  touch-action: pan-y !important;
}
.fadeIn-enter {
  opacity: 0;
}

.fadeIn-leave-active {
  opacity: 0;
  transition: all 0.2s step-end;
}

.fadeIn-enter .modal,
.fadeIn-leave-active.modal {
  transform: scale(1.1);
}

.overlay-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 999;
  transition: opacity 0.2s ease;
}

@media (max-width: 900px) {
  .modal-custom{
    width: 100%;
    height: 70%;
  }
}

</style>