<template>
  <div
    class="container gap-4 py-3 gap-lg-5 py-lg-5 d-flex flex-column align-items-center w-100"
  >
    <h2
      v-if="data.title"
      class="py-4 px-5 text-center text-white fs-subtitle bg-main rounded-5 w-fit-content fw-bold fst-italic"
      :style="{
        color: data?.bottomTitleColor?.hex + '!important',
        backgroundColor: data?.bottomTitleContainerColor?.hex + '!important',
      }"
    >
      {{ data.title }}
    </h2>
    <h2
      v-if="data.mainText"
      class="text-center text-main fs-max fw-bold fst-italic"
      :style="{ color: data?.bottomMainColor?.hex + '!important' }"
    >
      {{ data.mainText }}
    </h2>
    <p
      v-if="data.linkDescription"
      class="text-center fs-body text-main fw-bold"
      :style="{ color: data?.bottomLinkDescriptionColor?.hex + '!important' }"
    >
      {{ data.linkDescription }}
    </p>
    <a
      :href="bottomLink"
      class="gap-2 p-3 btn btn-main rounded-5 d-flex align-items-center fw-bold fs-body-large color-secondary fst-italic"
      ><i :class="bottomLinkIcon"></i>{{
        data?.bottomLinkText ?? "euro-petrol.com"
    }}</a>
    <p
      v-if="data.bottomText"
      class="text-main fst-italic"
      :style="{ color: data?.bottomColor?.hex + '!important' }"
    >
      {{ data.bottomText }}
    </p>
  </div>
</template>

<script>
export default {
  name: "JobBottomSection",

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {},

  computed: {
    bottomLink() {
      if (this.data?.bottomLink) {
        switch (this.data?.bottomLinkType) {
          case "email":
            return "mailto:" + this.data.bottomLink;
          case "phone":
            return "tel:" + this.data.bottomLink;
          default:
            return this.data.bottomLink;
        }
      }

      return "https://www.euro-petrol.com/";
    },

    bottomLinkIcon() {
      if (this.data?.bottomLink) {
        switch (this.data?.bottomLinkType) {
          case "email":
            return "ri-mail-line";
          case "phone":
            return "ri-phone-line";
        }
      }

      return "ri-global-line";
    },
  },
};
</script>

<style scoped>
.rounded-5 {
  border-radius: 0.8rem;
}

@media (max-width: 1024px) {
  .fs-max {
    font-size: 2.5rem !important;
  }

  .fs-subtitle {
    font-size: 2.25rem !important;
  }

  .fs-body-large {
    font-size: 1.25rem !important;
  }

  .fs-body {
    font-size: 1.25rem !important;
  }
}

.fs-max {
  font-size: 3rem;
}

.fs-subtitle {
  font-size: 2.8rem;
}

.fs-body-large {
  font-size: 1.75rem;
}

.fs-body {
  font-size: 1.5rem;
}
</style>
