<template>
    <AlternativeHeader />

    <!-- Header Image -->
    <div class="container-fluid d-flex justify-content-center my-5">
        <img class="img-fluid mx-h-300 max-h-80vh w-100" src="/img/karijera.jpg" alt="kasir-header" />
    </div>

    <!-- Open Positions Chargers Section Start -->
    <section id="open-positions" class="d-flex justify-content-center py-4 mt-5">
        <div class="container">
            <!-- Main Title with Subtitle -->
            <h1 class="section-title color-main my-4 text-center">Otvorene pozicije</h1>
            <p class="section-subtitle text-center">Lista otvorenih pozicija u Euro Petrolu.</p>
            <!-- Main Title with Subtitle End -->

            <div v-if="loading" class="loading vh-100 d-flex justify-content-center align-items-center">
                <img src="../assets/blog/spinner.svg" alt=""/>
            </div>

            <div v-if="error" class="error">
                {{error}}
            </div>

            <!-- Job List Start -->
            <div class="d-flex flex-column gap-3 my-5">

                <!-- No jobs available start -->
                <div class="job-item w-100" v-if="!jobs">
                    <div class="d-flex flex-wrap justify-content-center align-items-center">
                        <span>Trenutno nemamo otvorene pozicije.</span>
                    </div>
                </div>
                <!-- No jobs available end -->

                <!-- Job Item Start -->
                <template
                    v-if="jobs"
                >
                    <div
                        v-for="job, index in jobs"
                        :key="job._id"
                        class="d-flex flex-lg-row flex-column gap-lg-0 gap-3 flex-wrap justify-content-between align-items-center flex-wrap"
                    >
                        <img
                            class="img-fluid col-lg-6"
                            :class="index % 2 !== 0 ? 'order-lg-2' : ''"
                            :src="imageUrlFor(job.image)"
                        />
                        <div class="col-lg-6 row" :class="job.highlightedProperty ? 'mb-5' : ''">
                            <div class="col-12 d-flex mb-2">
                                <button
                                    v-if="job.highlightedProperty"
                                    class="btn btn-main px-3 rounded-0 ms-auto highlighted"
                                >
                                    {{job.highlightedProperty}}
                                </button>
                            </div>
                            <div class="col-sm-1 col-0"></div>
                            <div class="col-sm-10 col-12">
                                <h2 class="job-title mb-0">{{job.title}}</h2>
                                <div class="d-flex align-items-center gap-2 mb-3">
                                    <i class="ri-map-pin-2-line fs-3 text-main lh-1"></i>
                                    <p class="mb-0 fw-bold text-dark">
                                        <span
                                            v-for="location, index in job.locations"
                                            :key="`location-${index}`"
                                        >
                                            {{ location + (index &lt; job.locations.length - 1 ? ", " : "") }}
                                        </span>
                                    </p>
                                </div>
                                <SanityBlocks :blocks="job.description" />
                                <router-link
                                    :to="`/otvorene-pozicije/${job.slug.current}`"
                                    class="btn btn-secondary lh-lg rounded-0 d-flex align-items-center justify-content-between gap-1 w-sm-100 px-4 w-fit-content"
                                    :class="job.description ? 'mt-3' : ''"
                                    type="submit"
                                >Saznaj više<i class="ri-arrow-right-line color-main"></i></router-link>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- Job Item End -->

            </div>
            <!-- Job List End -->
        </div>
    </section>
    <!-- Open Positions Section End -->
</template>

<script>
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";
import moment from 'moment';
import AlternativeHeader from "../components/UI/AlternativeHeader.vue";
import { SanityBlocks } from "sanity-blocks-vue-component";

const imageBuilder = imageUrlBuilder(sanity);

const query = `*[_type == "carriers"] | order(orderNumber){
  _id, title, locations, description, highlightedProperty, slug,
  "image": listingImage{
        asset->{
        _id,
        url
    }
  },
}[0...50]`;

export default {
    name: "Job openings",
    data() {
        return {
            loading: true,
            jobs: [],
        };
    },
    created() {
        this.fetchData();
    },
    methods: {

        moment,

        imageUrlFor(source) {
            return imageBuilder.image(source);
        },

        fetchData() {
            this.error = this.job = null;
            this.loading = true;
            sanity.fetch(query).then(
                (jobs) => {
                    this.loading = false;
                    this.jobs = jobs;
                },
                (error) => {
                    this.error = error;
                }
            );
        },
    },

    computed: {
    },

    components: {
        AlternativeHeader,
        SanityBlocks
    },
};

</script>

<style scoped>
.highlighted {
    color: var(--color-secondary);
    font-weight: 600;
}

.job-title {
    font-weight: 800;
    color: var(--color-main-dark);
}

.mw-47rem{
    max-width: 47em;
}

.jd-flex{
    display: flex;
    gap: 5rem;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

@media (max-width: 1200px) {
  .jd-flex {
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
