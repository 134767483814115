<template>
  <!-- Gas Station Toggler -->
  <div
    class="gas-station-toggler position-relative gas-station-night"
    :class="{ 'gas-station-daylight': isDay }"
  >
    <div class="d-none position-absolute" style="top: 10px; right: 10px">
      <a
        class="time-toggler"
        @click="changeTime(true)"
        :class="{ 'opacity-20': !isDay }"
        ><i class="ri-sun-line"></i
      ></a>
      <a
        class="time-toggler"
        @click="changeTime(false)"
        :class="{ 'opacity-20': isDay }"
        ><i class="ri-moon-clear-line"></i
      ></a>
    </div>
  </div>
  <!-- Gas Station Toggler End -->
</template>

<script>
export default {
  data() {
    return {
      isDay: true,
    };
  },
  methods: {
    changeTime(bool) {
      this.isDay = bool;
    },
  },
  created() {
    const currentHour = new Date().getHours();
    if (currentHour >= 7 && currentHour <= 17) {
      this.isDay = true;
    } else {
      this.isDay = false;
    }
  },
};
</script>

<style scoped>
.gas-station-toggler {
  width: 100%;
  height: 39.06em;
  background-position: top;
  background-size: cover;
}

/* Class for gas station night mode */
.gas-station-night {
  background-image: url("../../assets/gas-station/noc_resize.jpg");
}

/* Class for gas station light mode */
.gas-station-daylight {
  background-image: url("/img/gas-pump-su.jpg");
}

.time-toggler {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 2em;
  height: 2em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fff;
  text-decoration: none;
  color: inherit;
  font-size: 25px;
  margin-bottom: 0.25em;
}

.opacity-20 {
  opacity: 0.2 !important;
}

@media (max-width: 900px) {
  .gas-station-toggler{
    height: 23.06em;
  }
}
</style>
