<template>
  <!-- api-key="AIzaSyA9iMpjstgjVffJc2sEPealV1IC4f4nCEQ" -->
  <GoogleMap api-key="AIzaSyAboxdDSraLV0k-tlVX0dSYIM3Fpp23EV0" style="height: 700px;" :center="mapCenter" :zoom="7">
    <div v-for="station in stations" :key="station.nid">
      <Marker
        :options="{
          position: {
            lat: station.lat,
            lng: station.lng
          }
        }"
      />
    </div>
  </GoogleMap>

</template>

<script>
import { defineComponent, ref } from 'vue';
import { GoogleMap, Marker } from 'vue3-google-map';

export default defineComponent({
  props: {
    serviceID: String,
    gasStations: Array
  },

  components: { GoogleMap, Marker },

  setup(props) {
    const stations = ref(props.gasStations);

    stations.value.map((station) => {
      station.lat = parseFloat(station.lat);
      station.lng = parseFloat(station.lng);
      station.services = station.services.map((service) => service.product);

      return station;
    })

    // Return all the stations if there is not service ID sent in the URL
    if (!props.serviceID)
        return {stations: stations.value, mapCenter: stations.value[0]};

    // Filter stations by the service (service ID from the url)
    for (let i in stations) {
        const station = stations[i];
        let services = station.value.services;

        if (!services.includes(parseInt(props.serviceID))) {
            delete stations.value[i];
        }
    }

     return {stations: stations.value, mapCenter: stations.value[0]};
  },
})
</script>
